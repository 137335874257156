import React, { useState, useEffect } from 'react';
import useUser from '../../hooks/useUser';
import { wishList } from '../../redux/slice/wishSlice';
import { addWishList } from '../../redux/slice/playerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';

const WishList = () => {
 const token = useUser();
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [filter, setFilter] = useState('All');
  useEffect(() => {
    handlePlayer();
  }, []);


    const handlePlayer = () => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:token.teamId
          } ;
          const jsonData = await dispatch(wishList(datapost));
          const filteredPlayer = jsonData.payload.filter(obj => {
            if(filter === 'Male'){
              return obj.gender === 'M';
            }else if(filter === 'Female'){
              return obj.gender === 'F';
            }else if(filter === 'Singles'){
              return obj.speciality === filter;
            }else if(filter === 'Doubles'){
              return obj.speciality === filter;
            }else if(filter === 'Indian'){
              return obj.nationality === 'India';
            }else{
              return obj;
            }
          });
          setData(jsonData.payload);
          setFilteredPlayers(filteredPlayer);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

      function changeFilter(event){
        setFilter(event.target.value)
      }

    const handleNavigate = (player) => {
      const queryParams = new URLSearchParams({
        player: player,
      }).toString();
      window.open('/player-detail?'+queryParams, '_blank');
    };

    const handleFilter = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        if (selectedFilter === 'All') {
            setFilteredPlayers(data); // Reset to all players
        } else {
           const filteredPlayer = data.filter(obj => {
            if(selectedFilter === 'Male'){
              return obj.gender === 'M';
            }else if(selectedFilter === 'Female'){
              return obj.gender === 'F';
            }else if(selectedFilter === 'Singles'){
              return obj.speciality === selectedFilter;
            }else if(selectedFilter === 'Doubles'){
              return obj.speciality === selectedFilter;
            }else if(selectedFilter === 'Indian'){
              return obj.nationality === 'India';
            }else{
              return obj;
            }
          });
         setFilteredPlayers(filteredPlayer);
        }
        
    };

    const handleAddWish = (event) => {
      var player_id = event;
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:token.teamId,
            player_id:player_id,
          } ;
          const jsonData = await dispatch(addWishList(datapost));
          handlePlayer();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    };

    const search = require('../../assets/images/search.png');
    const setting = require('../../assets/images/setting.png');
    const player = require('../../assets/images/player.png');
    const heart = require('../../assets/images/heart.png');
    const heartFill = require('../../assets/images/heartFilled.png');
    const male_profile = require('../../assets/images/male_profile.png');
    const female_profile = require('../../assets/images/female_profile.png');
    return (

       <div style={{width: '100%', height: '100%', position: 'relative', background: '#F8F9FA'}}>
        
        
        <div style={{width: '150px', height: '25px', left: '85%', top: '-25px', position: 'absolute'}}>
         <select name="fillter" value={filter}  className="fillterData" onChange={handleFilter}>
           <option value="">Select Filter</option>
           <option value="Male">Male</option>
           <option value="Female">Female</option>
           <option value="Singles">Singles</option>
           <option value="Doubles">Doubles</option>
           <option value="Indian">Indian</option>
         </select>

          
        </div>
       
          
          <div style={{width: '98%', margin:'10px', top: '38px', position: 'absolute'}}>
            <div style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', display: 'grid', gridTemplateColumns:'repeat(5, 1fr)'}}>
              
              {filteredPlayers.length > 0 ?
                filteredPlayers.map((player,index) => (
                 <div style={{width: '100%', height: 190, position: 'relative'}} key={index} >
                    <div style={{width: 150, height: 150, left: 0, top: 0, position: 'absolute', boxShadow: '4px 6px 4px rgba(0, 0, 0, 0.30)', borderRadius:150, transition: 'transform 0.3s ease', cursor: 'pointer'}}onMouseEnter={(e) => {
                          e.target.style.transform = 'scale(1.05)'; 
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.transform = 'scale(1)';
                        }}> 
                          <img style={{width: 140, height: 140, left:'3%', top: 8, position: 'absolute', borderRadius: 110, transition: 'transform 0.3s ease', objectFit:'cover'}} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : (player.gender ==='F' ? female_profile : male_profile)}  onClick={()=>handleNavigate(player.id)} /> 
                    </div>
                   
                    <div style={{width: '48%',left: '55%', top: '10%', position: 'absolute', color: '#464255', fontSize: 17, fontFamily: 'Roboto', fontWeight: '800', wordWrap: 'break-word', textTransform:'uppercase'}} >{player.firstname+' '+player.lastname}</div>
                          <div style={{width: '50%', height: 25, left: '55%', top: '35%', position: 'absolute'}}>
                          <div style={{left: 0, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 17, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.age}</div>
                          <div style={{width: 17, height: 0, left: 25, top: 20, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1.5px #464255 solid'}}></div>
                          <div style={{left: 25, width: 25, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 17, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.gender}</div>
                          <div style={{width: 17, height: 0, left: 50, top: 20, position: 'absolute', transform: 'rotate(-90deg)', transformOrigin: '0 0', border: '1.5px #464255 solid'}}></div>
                          <div style={{left: 55, position: 'absolute', textAlign: 'center', color: '#464255', fontSize: 17, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word'}}>{player.speciality}</div>
                          </div>
                          <img style={{width: 45, height: 25, left: '53%', top: '55%', position: 'relative', boxShadow: '4px 6px 4px rgba(0, 0, 0, 0.30)'}} src={require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`)} />
                   <img onClick={()=>handleAddWish(player.id)} style={{width: 40, height: 40, left: '60%', top: '60%', position: 'relative'}}  src={player.isInWishlist === true ? heartFill : heart} />
                </div>   
                  
              ))
              :""}


              
            </div>
            <div style={{width: '106%', height: '20px', background: '#FBB13A',position: 'relative', right:'5%', top:'100vh'}}>
            <div style={{color: '#464255', fontSize: '17px', textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700}}>© 2024 WPBL *All data collected is based on the information provided by the players</div>
        </div>
            
          </div>
      
        
      </div>
    );
    
};

export default WishList;