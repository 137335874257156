import React, { useState, useEffect  } from 'react';
import useUser from '../../hooks/useUser';
import { getDraftList,getDraftListSkipped } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import wpblLogoCss from './wpblLogo.module.css';

const WpblLogo = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    
  }, []);


    const handleNextPage = (datateam) => {
        navigate('/timer-team');
    }

    const handleAllPage = (datateam) => {
        navigate('/all-team');
    }

     const logo = require('../../assets/images/wpbl-logo.webp');
    return (
        <div className={wpblLogoCss.gridContainer}>
          <div className={wpblLogoCss.gridItem}></div>
          <div className={wpblLogoCss.gridItem,wpblLogoCss.teamName}></div>
          <div className={wpblLogoCss.gridItem}></div>
          <div className={wpblLogoCss.gridItem}></div>
          <div className={wpblLogoCss.gridItem}><img className={wpblLogoCss.logoTeam} src={logo}  onClick={()=>handleNextPage()} /></div>
          <div className={wpblLogoCss.gridItem}></div>
          <div className={wpblLogoCss.gridItem}></div>
          <div className={wpblLogoCss.gridItem,wpblLogoCss.teamName} onClick={()=>handleAllPage()} >Welcome to Player Draft.</div>
          <div className={wpblLogoCss.gridItem}></div>
        </div>
    );
};

export default WpblLogo;