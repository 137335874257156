import React, { useState, useEffect } from 'react';
import useUser from '../../hooks/useUser';
import { allTeamList } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import socket from '../../socket';

const AllTeam = () => {
  const token = useUser();
  var imageUrl = serverUrl.imageUrlProfile;
  var imageUrlTeam = serverUrl.imageUrlTeam;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const result = await dispatch(playerList());
  // console.log('in',result);
  const [data, setData] = useState([]);
  useEffect(() => {
    handlePlayer();
    socket.on('update-teams', (data) => {
        handlePlayer();
    });
    
  }, []);

   const handlePlayer = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            user_id:token.userId
          } ;
          const jsonData = await dispatch(allTeamList(datapost));
          setData(jsonData.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

    const handleNavigate = (player) => {
        handlePlayer();
      // navigate('/unsold-player');
    };

    const logo = require('../../assets/images/wpbl-logo.webp');
    const profileIcon = require('../../assets/images/icons8-user-icon-50.png');
    const female = require('../../assets/images/Feo.png');
    const male = require('../../assets/images/Meo.png');
    return (
      <span>
         <div style={{width: '100%',top: 32, gap:'1.5px',  position: 'relative', alignItems:'center', display:'flex', flexDirection:'column'}}>
      {data.length > 0 ?
        data.map((team,index) => (
        <div style={{width: '100%', height: 130, position: 'relative', alignItems:'center', display:'flex',flexDirection: 'column', justifyContent:'center'}} key={index}>
          <div style={{width: '100%', height: 130, left: 0, top: 0, position: 'relative', flexDirection:'row', borderBottom: '1px #adadb4 solid', display:'flex', gap:'10px'}} >
          <div style={{width: '15%', height: 130,position: 'relative', alignItems:'center', background: 'rgb(251 177 58)', display:'flex', flexDirection:'column', gap:'10px', justifyContent:'center'}} >
            <div style={{textAlign:'center', width:'130px', position: 'relative', color: 'white', textTransform:'uppercase', fontSize: 20, fontFamily: 'Roboto', fontWeight: '600', margin:'5px', wordWrap: 'break-word'}} onClick={handleNavigate}>{team.name}</div>
            <div style={{width:'100%',display:'flex',flexDirection:'row', top:20, gap:'15px', position:'relative', justifyContent:'center'}}>
                <div style={{width:'auto', position: 'relative'}}><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto", fontWeight: "600", wordWrap: "break-word" }}>M - </span><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto", fontWeight: "600", wordWrap: "break-word"}}>{team.malePlayer}</span></div>
                <div style={{width:'auto',position: 'relative'}}><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto", fontWeight: "600",wordWrap: "break-word" }}>F - </span><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto", fontWeight: "600", wordWrap: "break-word"}}>{team.femalePlayer}</span></div>
                <div style={{width:'auto',position: 'relative', textAlign:'end'}}><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto",  fontWeight: "600", wordWrap: "break-word" }}>I - </span><span style={{ color: "white", fontSize: 15, fontFamily: "Roboto", fontWeight: "600", wordWrap: "break-word"}}>{team.indianPlayer}</span></div>
              </div>
          </div>
          
          {team.playerData.length > 0 ?
            team.playerData.map((player,indexData) => (

          <div style={{width: '70%', display:'flex', borderRadius:'12px', flexDirection:'row', height: 130, zIndex:'1', position: 'relative', background: 'white'}} key={indexData}>
          {player.player_id !== 0 ?
            <div style={{width: '100%', height: 130, position: 'relative'}} key={index} >
              <div style={{display:'flex', flexDirection:'row', position:'relative', gap:'50%'}}>
                    <img style={{width: 10, height: 10, left: '9%', top: 2, position: 'relative'}} src={player.gender === "F" ? female : male} />
                    <img style={{width: 30, height: 20, left: 127, top: 2, position: 'absolute', boxShadow: '4px 6px 4px rgba(0, 0, 0, 0.30)', borderRadius:'2px', display: player.nationality === "India" ? 'flex' : 'none'}} src={require(`../../assets/country_flags/${player.nationality.toUpperCase()}.png`)} />

                    </div>
                    <div style={{width: 95, height:95, left: '20%', top: 0, position: 'absolute', boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.30)', borderRadius:150, transition: 'transform 0.3s ease', cursor: 'pointer'}}onMouseEnter={(e) => {
                          e.target.style.transform = 'scale(1.05)'; 
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.transform = 'scale(1)';
                        }}> 
                          <img style={{width: 90, height: 90, left:'3%', top: 3, position: 'absolute', borderRadius: 110, transition: 'transform 0.3s ease', objectFit:'cover'}} src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : profileIcon} /> 
                    </div>
                    

                   
                    <div style={{width: '100%', display:'flex', alignItems:'center', lineHeight:'1',justifyContent:'center', top: '66%', position: 'relative', textAlign:'center', color: '#464255', fontSize: 14, fontFamily: 'Roboto', fontWeight: '800',height:'35px', wordWrap: 'break-word', textTransform:'uppercase'}} >{player.player_name}</div>
                    
                       </div>
         
            :
            <span>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', height:'131px',width: '152px', justifyContent:'center'}}>
                  <img style={{ width: '100px', height: '100px',position: 'relative'}} src={profileIcon}/>
                </div>
              </span>
          } 
        </div>
      ))
      :""}
      </div>
    </div>
   ))
   :""}
   </div>
   </span>
    );
};

export default AllTeam;