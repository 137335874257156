import React, { useState, useEffect  } from 'react';
import useUser from '../../hooks/useUser';
import { playerSelected } from '../../redux/slice/playerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import soldPlayerCss from './soldPlayer.module.css';
import Confetti from 'react-confetti';
import Swal from 'sweetalert2';

const SoldPlayer = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const currentDraftData= localStorage.getItem('currentDraft');
  const currentDraft = JSON.parse(currentDraftData);
  const allDraftData= localStorage.getItem('draft');
  const allDraft = JSON.parse(allDraftData);
  const [confetti, setConfetti] = useState(false);
  const [imageData, setImageData] = useState(false); 


  useEffect(() => {
    setConfetti(true);
    setTimeout(() => {
      setImageData(true)
    }, 3000);
    handlePlayerDraft();
  }, []);

  const handlePlayerDraft = () => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:'',
          } ;
          const jsonData = await dispatch(playerSelected(datapost));
          setData(jsonData.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

    const handleNextPage = () => {
      const allDraftData= localStorage.getItem('draft');
      var draftData = JSON.parse(allDraftData);
      if(draftData !== null && draftData !== undefined && draftData !== '' ){
        var currentDraftDataMain = localStorage.getItem('currentDraft');
        var currentDraftData = JSON.parse(currentDraftDataMain);
        var nextId = parseInt(currentDraftData.sequence) + 1;
        var draftCurrent = draftData.find(draft => draft.sequence === nextId);
        if(draftCurrent === undefined){
          navigate('/all-team');
        }else{
          localStorage.setItem('currentDraft',  JSON.stringify(draftCurrent));
          navigate('/timer-team');
        }
      }
    }

    const tape = require('../../assets/images/Tape.png');
    const selected = require('../../assets/images/draft_new.png');
    const male_profile = require('../../assets/images/male_profile.png');
    const female_profile = require('../../assets/images/female_profile.png');
    return (
      <div className={soldPlayerCss.bodyDiv} onClick={handleNextPage}>
      {confetti && <Confetti width={window.innerWidth} height={850} />}
      {data !== null ?
            <div className={soldPlayerCss.playerCard} >
              <div className={soldPlayerCss.photoSection}>
               <img className={soldPlayerCss.tabeImg} src={tape} alt="Tape" />
                <div className={soldPlayerCss.photoWrapper} >
                  <img src={data.profilePhoto !== null && data.profilePhoto !=='' ? imageUrl+data.profilePhoto : (data.gender ==='Female' ? female_profile : male_profile)} alt="Player Photo" className={soldPlayerCss.playerPhoto} />
                  <div className={soldPlayerCss.selectedBadge}>
                    {imageData === true ?
                      <img className={soldPlayerCss.selectedImg} src={selected} alt="Tape" /> :""
                    }
                  </div>
                </div>
              </div>
              <div className={soldPlayerCss.infoSection}>
                <div className={soldPlayerCss.flagAndName}>
                  <img src={data.nationality !== '' ? require('../../assets/country_flags/'+data.nationality.toUpperCase()+'.png') : ""} alt="nationality Flag" className={soldPlayerCss.countryFlag}/>
                  <h1 className={soldPlayerCss.playerName}>{data.name}</h1>
                </div>
                <div className={soldPlayerCss.details}>
                  <p><strong>Playing Hand:</strong> {data.playingHand}</p>
                  <p><strong>Age:</strong> {data.age+" years"}</p>
                  <p><strong>Country:</strong> {data.nationality}</p>
                </div>
              </div>
            </div>
          : ""
        }
      </div>
    );
};

export default SoldPlayer;