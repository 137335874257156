import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header/Header';
import HeaderOther from './HeaderOther/HeaderOther';
import HeaderEmpty from './HeaderEmpty/HeaderEmpty';

const Layout = () => {
  const location = useLocation();

  // Exclude Header from certain routes
  const hideHeaderOnPaths = ['/login','/mobile-not-supported','/unsold-player','/timer-team','/team-logo','/sold-player','/team-skip','/all-team','/wpbl-logo'];
  const otherHeaderOnPaths = ['/unsold-player','/timer-team','/team-logo','/sold-player','/team-skip','/wpbl-logo'];
  const shouldShowHeader = !hideHeaderOnPaths.includes(location.pathname);
  const shouldShowHeaderOther = otherHeaderOnPaths.includes(location.pathname);

  return (
    <>
      {shouldShowHeader ? (
        <>
          <Header />
          <Outlet /> {/* Render child routes */}
        </>
      ) : shouldShowHeaderOther ? (
        <>
          <HeaderOther />
          <Outlet /> {/* Render child routes */}
        </>
      ) : 
       <><HeaderEmpty />
        <Outlet /> </>
    }
    </>

  );
};

export default Layout;
