import { io } from 'socket.io-client';
import serverUrl from './configs/constant'
const URL = serverUrl.soketUrl
const socket = io(URL, {
    reconnection: true,        // Enable automatic reconnection
    reconnectionAttempts: 500,   // Number of attempts before giving up
    // reconnectionDelay: 1000,   // Initial delay between attempts (in ms)
    // reconnectionDelayMax: 5000 // Maximum delay between attempts (in ms)
}); // Backend URL
export default socket;
