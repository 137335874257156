import React, {useState, useEffect}from 'react';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { playerSearch } from '../../redux/slice/playerSlice';
import { useNavigate,useLocation,Link  } from 'react-router-dom';
import { logout,regenerateToken } from '../../redux/slice/authSlice';
import useUser from '../../hooks/useUser';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import socket from '../../socket'; // Your Socket.IO client setup

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useUser();
  const location = useLocation();
  var locationdata = location.pathname.replace(/[-]/g, ' ');
  locationdata = locationdata.replace(/[\/]/g, '');
  const locationNameMain = locationdata.toUpperCase();
  const locationName = locationNameMain.split(' ');
  const locationFirstName = (locationName[0] ? locationName[0] : "");
  const locationLastName = (locationName[1] ? locationName[1] : "");
  const [data, setData] = useState([]);
  var teamNameData =token?.teamName;
  const [teamName, setteamName] = useState(teamNameData);
  var items =data;


   const [isOpen, setIsOpen] = useState(false);
   const [isOpenOther, setIsOpenOther] = useState(false);

    useEffect(() => {
      if(token?.userType !== 1){
        if(teamName === '' || teamName === null || teamName === undefined){
            const fetchData = async () => {
            try {
              var datapost = {} ;
              const jsonData = await dispatch(regenerateToken(datapost));
              var decodetoken = jwtDecode(jsonData.payload.token);
              var tokenData = decodetoken.teamName;
              setteamName(tokenData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();
        }
      }
    }, [dispatch]);

  const toggleMenu = () => {
    setIsOpenOther(false);
    setIsOpen(!isOpen);
  };


  const toggleMenuOther = () => {
    setIsOpen(false);
    console.log('toggleMenuOther',isOpenOther)
    setIsOpenOther(!isOpenOther);
  };

   const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left',cursor: 'pointer' }}>{item.name}</span>
      </>
    )
  }

  const handleNavigate = (player) => {
    navigate(player);
  };

  const handleOnSearch = (string, results) => {
    const fetchData = async () => {
        try {
          var datapost = {
            keyword:string,
          }
          const jsonData = await dispatch(playerSearch(datapost));
          setData(jsonData.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      if(string !==''){
        fetchData();
      }
  }

   const handleOnSelect = (item) => {
    if(item){
      var player = item.id;
      if(player){
         const queryParams = new URLSearchParams({
            player: player,
          }).toString();
          window.open('/player-detail?'+queryParams, '_blank');
      }
    }
  }

  const handleLogout = () => {
    dispatch(logout()); // Clears user and token from store
    navigate('/'); // Redirect to login or home page
  };

  const handleHomePage = () => {
    navigate('/player-list'); // Redirect to login or home page
  };
    const logo = require('../../assets/images/wpbl-logo.webp');
    const profile = require('../../assets/images/profile.png');
    const notification = require('../../assets/images/notification.png');
    const search = require('../../assets/images/search.png');
    const setting = require('../../assets/images/logout.png');
    const player = require('../../assets/images/gray_extracted.png');
  return (
    <div style={{ width: '100%', height: '150px', left: '0px', top: '0px', position: 'relative'}} className={location.pathname === '/my-team/' ? 'headClass':""}>
      {/* Gradient Background */}
      <div
        style={{
          width: '100%',
          height: '60px',
          left: '0px',
          top: '0px',
          position: 'absolute',
          background: 'linear-gradient(90deg, #FBB13B 0%, #FBB13A 100%)',
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.25)',
        }}
      ></div>

      {/* Player Profile Circle */}
      <div
        style={{
          width: '140px',
          height: '140px',
          top: '0px',
          position: 'absolute',
          background: '#FBB13B',
          boxShadow: '-2px 10px 6px rgba(0, 0, 0, 0.25)',
          borderRadius: '80px',
        }}
      ></div>

      {/* Logo */}
      <img
        style={{ width: '120px', height: '120px', left: '15px', top: '20px', position: 'absolute',cursor: 'pointer' }}
        src={logo}
        alt="Logo"
        onClick={handleHomePage}
      />

      {/* Player Logo */}
      <img
        style={{
          width: '60px',
          height: '55px',
          left: '12%',
          position: 'absolute',
          opacity: 0.40,
        }}
        src={player}
        alt="Player Logo"
      />

      {/* Menu Icon */}
      <div style={{display:'flex', flexDirection:'row', width:'25%', left:'17%', position:'relative', gap:'20px', top:12}}>
      <div style={{
        width:'auto',
        top: '3px',
        position: 'relative',
        textAlign: 'start',
        color: 'white',
        fontSize: '25px',
        fontFamily: 'Roboto',
        fontStyle: 'italic',
        
      }}>
        <span style={{ fontWeight:'bold'}}>
         {locationFirstName}
        </span>
        <span style={{left: '3%', position:'relative', fontWeight:'400'}}>
         {locationLastName}
        </span>

      </div>
      <div onClick={toggleMenu}
        style={{
          width: '40px',
          height: '40px',
          paddingLeft: '3.33px',
          paddingRight: '3.33px',
          paddingTop: '8px',
          paddingBottom: '11px',
          position: 'relative',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex:'1'
        }}
      >
        {isOpen && token?.userType !== 1 && (
          <ul className="dropdown-menu">
            <li>
              <Link  to='/player-list'>Player List</Link >
            </li>
            <li>
              <Link  to='/wish-list'>Wishlist</Link >
            </li>
            <li>
              <Link  to='/my-team/'>My Team</Link >
            </li> 
             <li>
              <Link  to='/all-team'>All Team</Link >
            </li>
          </ul>
        )}
        {isOpen && token?.userType === 1 && (
          <ul className="dropdown-menu">
            <li>
              <Link  to='/player-list'>Player List</Link >
            </li>
            <li>
              <Link  to='/wpbl-logo'>Start Draft</Link >
            </li>
            <li>
              <Link  to='/all-team'>All Team</Link >
            </li>
            <li>
              <Link  to='/team-draft'>Current Draft</Link >
            </li>
          </ul>
        )}
        <div style={{ flex: '1 1 0', alignSelf: 'stretch', position: 'relative',cursor: 'pointer' }}>
          <div
            style={{
              width: '33.33px',
              height: '3.33px',
              position: 'absolute',
              background: '#F8F8F8',
            }}
          ></div>
          <div
            style={{
              width: '33.33px',
              height: '3.33px',
              position: 'absolute',
              top: '8.89px',
              background: 'rgba(248, 248, 248, 0.55)',
            }}
          ></div>
          <div
            style={{
              width: '33.33px',
              height: '3.33px',
              position: 'absolute',
              top: '17.78px',
              background: 'rgba(248, 248, 248, 0.55)',
            }}
          ></div>
          
        </div>
      </div>
      </div>

      {/* Player List Title */}
      
      

      {/* Search Bar Section */}
 <div style={{ width: '306px', height: '30px', left: '50%', top: '5%', position: 'absolute' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',

          }}
        >
          <style>
          {`
          

            }
          `}
        </style>

        <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            autoFocus
            formatResult={formatResult}
            onSelect={handleOnSelect}
            placehoder="search player"
            placeholder="SEARCH PLAYER..."
            className="search-input"
            styling={{
              zIndex: 2, // Ensure the dropdown shows above other elements
            }}
          />

        </div>
        
        
      </div>

      {/* Profile & Settings Icons */}
      <div
        style={{
          width: '30px',
          height: '0px',
          left: '70%',
          top: '30px',
          position: 'absolute',
          transform: 'rotate(-90deg)',
          border: '1px white solid',
        }}
      >
        </div>
      <div
        style={{
          width: '35px',
          height: '35px',
          left: '72%',
          top: '14px',
          position: 'absolute',
          background: '#FBB13A',
          borderRadius: '99px',
          border: '1px white solid',
        }}
      >
         <img
        style={{ width: '30px', height: '30px', left: '7%', top: '2px', position: 'absolute' }}
        src={profile}
        onClick={toggleMenuOther}
        alt="Profile"
      />
      {isOpenOther && (
        <ul className="dropdown-menu settingDrop" onClick={toggleMenuOther}>
          <li>
              <Link  to='/change-password'>Change Password</Link >
            </li> 
          <li onClick={handleLogout}>
            <Link  to='/player-list' >Logout</Link >
          </li>
        </ul>
      )}

    </div>
      

      {/* Admin Info */}
      <div
        style={{
          left: '75%',
          top: '14%',
          width:'auto',
          position: 'absolute',
          textAlign: 'left',
          color: 'white',
          fontSize: '18px',
          fontFamily: 'Roboto',
          fontStyle: 'italic',
          fontWeight: 700,
        }}
      >
        {teamName !== undefined && teamName !== '' ?
          token?.firstname+' '+token?.lastname+'('+teamName+')'
          :
          token?.firstname+' '+token?.lastname
        }
      </div>

      {/* Notification & Settings Icons */}
      <div
        style={{
          width: '35px',
          height: '35px',
          left: '94%',
          top: '14px',
          position: 'absolute',
          background: '#FBB13A',
          borderRadius: '99px',
          border: '1px white solid',
        }}
      >
         <img
        style={{ width: '25px', height: '25px', left: '15%', top: '15%', position: 'absolute' }}
        src={notification}
        alt="Notification"
      />
      <div
        style={{
          width: '25px',
          height: '25px',
          left: '75%',
          top: '-10px',
          position: 'relative',
          background: '#F63838',
          borderRadius: '25px',
        }}
      >
        <div
          style={{
            left: '-1',
            top: '4px',
            position: 'relative',
            textAlign: 'center',
            color: 'white',
            fontSize: '15px',
            fontFamily: 'Roboto',
            fontStyle: 'italic',
            fontWeight: 700,
          }}
        >
          0
        </div>
      </div>
      </div>
      

      {/* Notification Count */}
      

      {/* Settings Icon */}
    </div>
  );
};

export default Header;
