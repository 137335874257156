// src/features/api/apiSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {serverCallOther} from '../../modules/serverCall'
import Swal from 'sweetalert2'

export const playerList = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/all-player-wish`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const playerSelected = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/player-selected`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const playerListUnsold = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/unsold-list`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const playerListUnsoldAll = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/all-unsold-list`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const playerSearch = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/get-all-players-search`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const playerDetailsList = createAsyncThunk(
  'api/fetchData',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/player-details`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignTeam = createAsyncThunk(
  'api/assignTeam',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/assign-team/`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.message;
      
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeTeam = createAsyncThunk(
  'api/removeTeam',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/remove-team/`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.error;
      
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  'api/createUser',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`common/create-user/`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.message;
      if(Array.isArray(errorMSG)){
        var errorMSGData = [];
        for (var i = 0; i < errorMSG.length; i++) {
          errorMSGData.push(errorMSG[i].msg); 
        }
        errorMSG = errorMSGData;
      }
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

export const addWishList = createAsyncThunk(
  'api/addWishList',
  async (datapost, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`player/add-wish-list`,
          data:datapost
        });
      return response.data;
    } catch (error) {
      var errorMSG = error.response.data.message;
      if(Array.isArray(errorMSG)){
        var errorMSGData = [];
        for (var i = 0; i < errorMSG.length; i++) {
          errorMSGData.push(errorMSG[i].msg); 
        }
        errorMSG = errorMSGData;
      }
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMSG,
        });
      return rejectWithValue(error.response.data);
    }
  }
);

const playerSlice = createSlice({
  name: 'api',
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(playerList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(playerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(playerList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default playerSlice.reducer;
