import React, { useState, useEffect  } from 'react';
import useUser from '../../hooks/useUser';
import { getDraftList,getDraftListSkipped } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import teamLogoCss from './teamLogo.module.css';

const TeamLogo = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({name:'',teamLogo:''});
  const currentDraftData= localStorage.getItem('currentDraft');
  const currentDraft = JSON.parse(currentDraftData);
  const allDraftData= localStorage.getItem('draft');
  const allDraft = JSON.parse(allDraftData);

  useEffect(() => {
    if(allDraft === null || allDraft === undefined || allDraft === ''){
      if(currentDraft === null || currentDraft === undefined || currentDraft === ''){
          handleDraft();
      }
    }else{
     
      setData(currentDraft);
    }
  }, []);

  const handleDraft = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:'',
          } ;
          const jsonData = await dispatch(getDraftList(datapost));
          
          setData(jsonData.payload[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

    const handleNextPage = (datateam) => {
      if(datateam !== '' && datateam !== null && datateam !== undefined){
        navigate('/timer-team', { state: { datateam } });
      }
    }

    return (
        <div className={teamLogoCss.gridContainer}>
          <div className={teamLogoCss.gridItem}></div>
          <div className={teamLogoCss.gridItem,teamLogoCss.teamName}>{"Team "+data.name+", it's your turn to pick a player!"}</div>
          <div className={teamLogoCss.gridItem}></div>
          <div className={teamLogoCss.gridItem}></div>
          <div className={teamLogoCss.gridItem}><img className={teamLogoCss.logoTeam} src={data.teamLogo !== '' ? require(`../../assets/team_logo/${data.teamLogo}`) : ''}  onClick={()=>handleNextPage(data)} /></div>
          <div className={teamLogoCss.gridItem}></div>
          <div className={teamLogoCss.gridItem}></div>
          <div className={teamLogoCss.gridItem,teamLogoCss.teamName}>{data.name}</div>
          <div className={teamLogoCss.gridItem}></div>
        </div>
    );
};

export default TeamLogo;