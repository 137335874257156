import React, { useState, useEffect  } from 'react';
import useUser from '../../hooks/useUser';
import { getDraftListData } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import draftCss from './draft.module.css';

const Draft = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDraftData= localStorage.getItem('currentDraft');
  const currentDraft = JSON.parse(currentDraftData);
  var curSeq = 0;
  if(currentDraft !== undefined && currentDraft !== null && currentDraft !== ''){
    curSeq = currentDraft.sequence;
  }
  const [team, setTeam] = useState([]);

  useEffect(() => {
    handleDraft();
  }, []);

    const handleDraft = () => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:'',
          } ;
          const jsonData = await dispatch(getDraftListData(datapost));
          
          setTeam(jsonData.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

    const handleCurrentDraft = (draftCurrent) => {
      localStorage.setItem('currentDraft',  JSON.stringify(draftCurrent));
      window.location.reload();
    }


    return (
        <div className={draftCss.responsiveTableContainer}>
          <table className={draftCss.responsiveTable}>
            <thead>
              <tr>
                <th>No.</th>
                <th>Team Name</th>
                <th>Sequence</th>
                <th>Current Darft</th>
                <th>isSkipped</th>
                <th>Change Sequence</th>
              </tr>
            </thead>
            <tbody>
            {team.length > 0 ?
                team.map((teams,index) => (
              <tr className={curSeq === teams.sequence ? draftCss.currentTeam : ""}>
                <td>{index+1}</td>
                <td>{teams.name}</td>
                <td>{teams.sequence}</td>
                <td >{curSeq === teams.sequence ? 'Yes' : 'No'}</td>
                <td>{teams.isSkipped === true ? 'Yes' : 'No'}</td>
                <td><button type="button" className={draftCss.addBtn} onClick={()=> handleCurrentDraft(teams)}>Add Sequence</button></td>
              </tr>
              ))
              :
              <tr>
                <td colSpan="5">No Draft</td>
              </tr>

            }
            </tbody>
          </table>
        </div>

    );
};

export default Draft;