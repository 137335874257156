import React, { useState, useEffect  } from 'react';
import useUser from '../../hooks/useUser';
import { getDraftList,createDraftSkipped } from '../../redux/slice/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import teamSkipCss from './teamSkip.module.css';

const TeamSkip = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const datateam = location.state?.datateam;
  const [team, setTeam] = useState(datateam);

  useEffect(() => {
      if(team !== '' && team !== null && team !== undefined){
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:team.teamId,
            sequence:team.sequence,
          } ;
          const jsonData = await dispatch(createDraftSkipped(datapost));
          await dispatch(getDraftList(datapost));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, []);


    const handleNextPage = (datateam) => {
      if(datateam !== '' && datateam !== null && datateam !== undefined){
        navigate('/timer-team');
      }
    }
    const close = require('../../assets/images/close.png');
    return (
        <div className={teamSkipCss.gridContainer}>
          <div className={teamSkipCss.gridItem}></div>
          <div className={teamSkipCss.gridItem,teamSkipCss.teamName}>{"Team "+team.name+" ! You have missed your chance to pick a player!"}</div>
          <div className={teamSkipCss.gridItem}></div>
          <div className={teamSkipCss.gridItem}></div>
          <div className={teamSkipCss.gridItem}><img className={teamSkipCss.logoTeam} src={close}  onClick={()=>handleNextPage(team)} /></div>
          <div className={teamSkipCss.gridItem}></div>
          <div className={teamSkipCss.gridItem}></div>
          <div className={teamSkipCss.gridItem,teamSkipCss.teamName}>{team.name}</div>
          <div className={teamSkipCss.gridItem}></div>
        </div>
    );
};

export default TeamSkip;