import React, {useState, useEffect}from 'react';

const HeaderOther = () => {

    useEffect(() => {
            
    }, []);

 
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <div style={{ width: '100%', height: '120px', left: '0px', top: '0px', position: 'relative'}}>
      <div
        style={{
          width: '100%',
          height: '60px',
          left: '0px',
          top: '0px',
          position: 'absolute',
          background: 'linear-gradient(90deg, #FBB13B 0%, #FBB13A 100%)',
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.25)',
        }}
      ></div>
       <div
        style={{
          width: '140px',
          height: '140px',
          top: '0px',
          position: 'absolute',
          background: '#FBB13B',
          boxShadow: '-2px 10px 6px rgba(0, 0, 0, 0.25)',
          borderRadius: '80px',
        }}
      ></div>
      {/* Logo */}
      <img
        style={{ width: '120px', height: '120px', left: '15px', top: '20px', position: 'absolute',cursor: 'pointer' }}
        src={logo}
        alt="Logo"
      />
      
    </div>
  );
};

export default HeaderOther;
