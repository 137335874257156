import React, { useState, useEffect  } from 'react';
import { getDraftList } from '../../redux/slice/teamSlice';
import { useDispatch } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import { motion } from "framer-motion"; // Optional for animation
import timerTeamCss from './timerTeam.module.css';

const TimerTeam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [timeLeft, setTimeLeft] = useState(300); // Default to 10 minutes (600 seconds)
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const currentDraftData= localStorage.getItem('currentDraft');
  const currentDraft = JSON.parse(currentDraftData);
  const allDraftData= localStorage.getItem('draft');
  const allDraft = JSON.parse(allDraftData);
  const [team, setTeam] = useState(currentDraft);

  useEffect(() => {
    if(allDraft === null || allDraft === undefined || allDraft === ''){
      if(currentDraft === null || currentDraft === undefined || currentDraft === ''){
          handleDraft();
      }
    }
    let interval = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  const handleDraft = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:'',
          } ;
          const jsonData = await dispatch(getDraftList(datapost));
          
          setTeam(jsonData.payload[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

  // Format time into minutes and seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return {
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds,
    };
  };

  const handleSkipPage = (datateam) => {
    if(datateam !== '' && datateam !== null && datateam !== undefined){
            navigate('/team-skip', { state: { datateam } });
    }
  }

  const handleNextPage = (datateam) => {
      if(datateam !== '' && datateam !== null && datateam !== undefined){
             navigate('/sold-player');
      }
    }

  // Start or resume the timer
  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  // Pause the timer
  const handlePause = () => {
    setIsActive(false);
    setIsPaused(true);
  };

  // Reset the timer to the initial value
  const handleReset = () => {
    setIsActive(false);
    setIsPaused(false);
    setTimeLeft(300); // Reset to 10 minutes (600 seconds)
  };

  const { minutes, seconds } = formatTime(timeLeft);
  const logo = require('../../assets/images/wpbl-logo.webp');
  return (
    <div className={timerTeamCss.bodyDiv}>
    {team !== '' && team !== null ?
        <div className={timerTeamCss.container}>
          <header className={timerTeamCss.header}>
            <div className={timerTeamCss.teamLogo}>
              {/*<img src={team.teamLogo !== '' ? require(`../../assets/team_logo/${team.teamLogo}`) : ''} onClick={() => handleNextPage(team) } alt="Team Logo"/>*/}
              <div className={timerTeamCss.teamCircle}></div>
            </div>
          </header>
            <div className={timerTeamCss.textOverlay} onClick={() => handleNextPage(team) } >
              {team.name}
            </div>

        <div className={timerTeamCss.timer}>
          <div className={timerTeamCss.timerBox} onClick={isActive === false ? handleStart : handlePause}>
            <motion.span
                key={minutes}
                initial={{ opacity: 0, rotateX: -90 }}
                animate={{ opacity: 1, rotateX: 0 }}
                exit={{ opacity: 0, rotateX: 90 }}
                transition={{ duration: 0.5 }}
              >
                {minutes}
              </motion.span>
           
          </div>
          <div className={timerTeamCss.timerBoxDot} onClick={()=>handleSkipPage(team)}>:</div>
          <div className={timerTeamCss.timerBox}  onClick={handleReset}>
            <motion.span
                key={seconds}
                initial={{ opacity: 0, rotateX: -90 }}
                animate={{ opacity: 1, rotateX: 0 }}
                exit={{ opacity: 0, rotateX: 90 }}
                transition={{ duration: 0.5 }}
              >
                {seconds}
              </motion.span>
           
          </div>
        </div>
      </div>
      :""}
    </div>
  );
};



export default TimerTeam;