import React, {useState, useEffect}from 'react';

const HeaderEmpty = () => {

    useEffect(() => {
            
    }, []);

 
  return (
    <div>

    </div>
  );
};

export default HeaderEmpty;
