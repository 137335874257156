import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {serverCall, serverCallOther} from '../../modules/serverCall'
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2'

// Async action for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await serverCall({
          method:'POST',
          url:`login/`,
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data:credentials
        });
      return response.data; // Return user data
    } catch (error) {
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`change-password/`,
          data:credentials
        });
      return response.data; // Return user data
    } catch (error) {
      Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

export const regenerateToken = createAsyncThunk(
  'auth/regenerateToken',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await serverCallOther({
          method:'POST',
          url:`common/regenerate-token`,
          data:credentials
        });
      return response.data; // Return user data
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle error
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload.token); // Persist the token globally
    },
    clearToken: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token'); // Clear the token from storage
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('persist:root');
      localStorage.removeItem('currentDraft');
      localStorage.removeItem('draft');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        var decodetoken = jwtDecode(action.payload.token);
        state.isLoading = false;
        state.user = JSON.stringify(decodetoken);
        state.token = action.payload.token;
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(regenerateToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(regenerateToken.fulfilled, (state, action) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem('token');
        localStorage.removeItem('persist:root');
        var decodetoken = jwtDecode(action.payload.token);
        console.log('decodetoken',decodetoken);
        state.isLoading = false;
        state.user = JSON.stringify(decodetoken);
        state.token = action.payload.token;
        localStorage.setItem('token', action.payload.token);
      })
      .addCase(regenerateToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setToken, logout } = authSlice.actions;
export default authSlice.reducer;
