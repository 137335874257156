import "react-responsive-carousel/lib/styles/carousel.min.css";
import React, { useState, useEffect,useRef  } from 'react';
import useUser from '../../hooks/useUser';
import { playerListUnsoldAll } from '../../redux/slice/playerSlice';
import { useDispatch, useSelector  } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import serverUrl from '../../configs/constant';
import unsoldPlayerCss from './unsoldPlayer.module.css';
import { Carousel } from 'react-responsive-carousel';

const UnsoldPlayer = () => {
  var imageUrl = serverUrl.imageUrlProfile;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    handlePlayer();
  }, []); 

  const handlePlayer = (player) => {
      const fetchData = async () => {
        try {
          var datapost = {
            team_id:'',
          } ;
          const jsonData = await dispatch(playerListUnsoldAll(datapost));
          setData(jsonData.payload);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }

      const handleNavigate = (player) => {
        navigate('/all-team');
    };


    const tape = require('../../assets/images/Tape.png');
    const selected = require('../../assets/images/selected.png');
    const male_profile = require('../../assets/images/male_profile.png');
    const female_profile = require('../../assets/images/female_profile.png');
    return (
      <div className={unsoldPlayerCss.carouselRoot} onClick={handleNavigate}>
      {data.length > 0 ?
        <Carousel showArrows={false} showIndicators={false} autoPlay={true} infiniteLoop={true} showStatus={false} interval={2000} autoFocus={false} stopOnHover={false}>
          {data.map((player,index) => (
            <div className={unsoldPlayerCss.bodyDiv}>
              <div className={unsoldPlayerCss.playerCard}>
                  <div className={unsoldPlayerCss.photoSection}>
                   <img className={unsoldPlayerCss.tabeImg} src={tape} alt="Tape" />
                    <div className={unsoldPlayerCss.photoWrapper}>
                      <img src={player.profilePhoto !== null && player.profilePhoto !=='' ? imageUrl+player.profilePhoto : (player.gender ==='Female' ? female_profile : male_profile)} alt="Player Photo" className={unsoldPlayerCss.playerPhoto} />
                    </div>
                  </div>
                  <div className={unsoldPlayerCss.infoSection}>
                    <div className={unsoldPlayerCss.flagAndName}>
                      <img src={player.nationality !== '' ? require('../../assets/country_flags/'+player.nationality.toUpperCase()+'.png') : ""} alt="nationality Flag" className={unsoldPlayerCss.countryFlag}/>
                      <h1 className={unsoldPlayerCss.playerName}>{player.name}</h1>
                    </div>
                    <div className={unsoldPlayerCss.details}>
                      <p><strong>Player:</strong> {player.playingHand}</p>
                      <p><strong>Age:</strong> {player.age+" years"}</p>
                      <p><strong>Country:</strong> {player.nationality}</p>
                    </div>
                  </div>
                </div>
          </div>
        ))}
         </Carousel>
          : ""
        }
      </div>
    );
};

export default UnsoldPlayer;