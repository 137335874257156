// src/store.js
import { configureStore, combineReducers  } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import authReducer from '../slice/authSlice';
import playerReducer from '../slice/playerSlice';
import wishReducer from '../slice/wishSlice';
import teamReducer from '../slice/teamSlice';
import notificationReducer from '../slice/notificationSlice';

/*const preloadedState = {
  auth: {
    token: localStorage.getItem('token') || null,
    user: null,
  },
};
const store = configureStore({
  reducer: {
    auth: authReducer,
    api: apiReducer,
  },
  preloadedState,
});*/
// Define the persist configuration
const persistConfig = {
  key: 'root', // key used for storage
  storage, // the storage engine (default: localStorage)
  whitelist: ['auth'], // which reducer to persist
};

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  wish: wishReducer,
  team: teamReducer,
  notifications: notificationReducer,
});

// Apply persistReducer to rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

// Create persistor (used to persist the state)
const persistor = persistStore(store);

export default store; // Export store as default
export { persistor };
